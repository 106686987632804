@tailwind base;
@tailwind components;
@tailwind utilities;

/* VENDOR */

.vanilla-calendar-days {
    gap: 0.25rem;
}

.vanilla-calendar-day .vanilla-calendar-day__btn {
    pointer-events: none;
    background-color: #53f153;
    border-radius: 0;
    color: #000;
}

.vanilla-calendar-day .vanilla-calendar-day__btn.vanilla-calendar-day__btn_weekend {
    color: #000;
}

.vanilla-calendar-day .vanilla-calendar-day__btn.vanilla-calendar-day__btn_disabled {
    background-color: #ff5b5b;
}

.vanilla-calendar-day .vanilla-calendar-day__btn.vanilla-calendar-day__btn_today {
    font-weight: normal;
    color: inherit;
    position: relative;
    overflow: visible;
}

.vanilla-calendar-day .vanilla-calendar-day__btn.vanilla-calendar-day__btn_today:before {
    position: absolute;
    top: -2px;
    left: -2px;
    content: '';
    width: 7px;
    height: 7px;
    background-color: cornflowerblue;
    border-radius: 100%;
}

